import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Home/header"

const Hero = ({ heroImage }) => (
  <BackgroundImage
    id="hero"
    Tag="section"
    className="hero"
    fluid={heroImage.fluid}
    style={{backgroundPosition: 'top center'}}
  >
    <div className="overlay" />
    <div className="hero-container">
      <h1>Alex'n'Friends</h1>
      <h2>Η σελίδα δε βρέθηκε</h2>
      <Link className="btn-get-started" to="/el/">Αρχική</Link>
    </div>
  </BackgroundImage>
)

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      nav: kontentItemNavigationItem(system: {codename: {eq: "root"}, language: {eq: "el"}}) {
        fields {
          lang
        }
        elements {
          subitems {
            value {
              ... on kontent_item_navigation_item {
                elements {
                  title {
                    value
                  }
                  anchor {
                    value
                  }
                }
              }
            }
          }
        }
      }
      bgImage: file(relativePath: { eq: "404.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="404" lang={"el"} />
      <Header
        siteTitle={"404"}
        navItems={data.nav.elements.subitems.value}
        lang={"el"}
      />
      <main id="main">
        <Hero heroImage={data.bgImage.childImageSharp} />
      </main>
    </Layout>
  )
}

export default NotFoundPage
